<style lang="scss" scoped>
$height: 100px;
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $height;
  height: $height;
  line-height: $height;
  text-align: center;
}
.avatar {
  width: $height;
  height: $height;
  display: block;
}
</style>
<template>
  <div class="">
    <el-dialog
      title="編輯店鋪信息"
      v-model="state.dialogFormVisible"
      width="700px"
    >
      <el-form
        size="small"
        :inline="true"
        :model="state.ruleForm"
        ref="ruleForm"
        :rules="state.rules"
        label-width="120px"
        class="demo-form-inline"
      >
        <el-row>
          <el-col :span="23" :offset="3">
            <el-form-item
              label="登錄頁Banner"
              prop="banner"
              class="wrap-form-item-130"
            >
              <span class="tips"
                >用於app登錄展示、支持jpg、png格式，建議尺寸417*260px</span
              >
              <el-upload
                class="avatar-uploader"
                action="https://foursquare.bscd8.com/upload/uploadFiles"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                drag
                :on-progress="onProgress"
                :on-error="onError"
              >
                <img
                  v-if="state.ruleForm.banner"
                  :src="state.ruleForm.banner"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="23" :offset="3">
            <el-form-item label="商鋪名" prop="shop_name">
              <el-input
                v-model="state.ruleForm.shop_name"
                placeholder="請輸入商鋪名"
                class="wd250"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="23" :offset="3">
            <el-form-item label="聯繫郵箱" prop="email">
              <el-input
                v-model="state.ruleForm.email"
                :min="0"
                placeholder="請輸入聯繫郵箱"
                class="wd250"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="23" :offset="3">
            <el-form-item label="聯繫電話" prop="phone">
              <el-input
                v-model="state.ruleForm.phone"
                :min="0"
                placeholder="請輸入聯繫電話"
                class="wd250"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="23" :offset="3">
            <el-form-item label="商鋪地址" prop="address">
              <el-input
                v-model="state.ruleForm.address"
                placeholder="商鋪地址"
                class="wd250"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="23" :offset="3">
            <el-form-item label="最低庫存提醒" prop="min_stock">
              <el-input-number
                v-model="state.ruleForm.min_stock"
                placeholder="最低庫存提醒"
                class="wd250"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="23" :offset="3">
            <el-form-item label="營業時間" required>
              <el-time-picker
                is-range
                format="H:mm"
                v-model="state.time"
                range-separator="至"
                start-placeholder="開始時間"
                end-placeholder="結束時間"
                placeholder="選擇時間範圍"
              ></el-time-picker>
            </el-form-item>
          </el-col>
          <el-col :span="18" :offset="3">
            <el-form-item label="商品介紹">
              <div id="box1"></div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer flex_m_r">
        <el-button @click="state.dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit()">確 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  getCurrentInstance,
  ref,
  onMounted,
  nextTick,
} from "vue";
import E from "wangeditor";
import i18next from "i18next";
import defineLanguage from "@/plugins/defineLanguage";
import editorConfig from "@/plugins/editorConfig";

export default {
  emits: {
    onSuccess(data) {
      return data;
    },
  },
  setup(props, { emit }) {
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http, $loading, $message } = proxy;
    const ruleForm = ref();
    let editor = null;
    const state = reactive({
      dialogFormVisible: false,
      time: [new Date(2020, 4, 13, 8, 40), new Date(2020, 4, 13, 23, 59)],
      ruleForm: {
        id: "",
        shop_name: "",
        email: "",
        phone: "",
        details: "", //商鋪简介
        address: "",
        banner: "",
        start_business: "",
        end_business: "",
        min_stock: 0,
      },
      rules: {
        //表单验证
        shop_name: [
          { required: true, message: "請輸入商鋪名", trigger: "blur" },
        ],
        email: [
          { required: true, message: "請輸入聯繫郵箱", trigger: "blur" },
          //   { validator: emailText, trigger: "blur" },
        ],
        phone: [
          { required: true, message: "請輸入聯繫電話", trigger: "blur" },
          //   { validator: rPhoneNum, trigger: "blur" },
        ],
        banner: [
          { required: true, message: "請上傳Banner圖", trigger: "blur" },
        ],
        address: [
          { required: true, message: "請輸入商鋪地址", trigger: "blur" },
        ],
        min_stock: [
          { required: true, message: "最低庫存提醒", trigger: "blur" },
        ],
        time: [{ required: true, message: "請選擇營業時間", trigger: "blur" }],
        // details: [
        //   { required: true, message: "請輸入商鋪簡介", trigger: "submit" },
        // ],
      },
    });

    onMounted(() => {});

    const init = (data) => {
      console.log(data);
      state.dialogFormVisible = true;
      state.ruleForm = data;
      state.time[0] = data.start_business ? new Date(2020 / 4 / 1 + " " + data.start_business) : new Date();
      state.time[1] = data.end_business ? new Date(2020 / 4 / 1 + " " + data.end_business) : new Date();
      nextTick(() => {
        editor = new E("#box1");

        Object.assign(editor.config, editorConfig);
        // 选择语言
        editor.config.lang = "fanti";
        // 自定义语言
        editor.config.languages["fanti"] = defineLanguage;
        // 引入 i18next 插件
        editor.i18next = i18next;
        editor.create();
        editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
          // resultFiles 是 input 中选中的文件列表
          // insertImgFn 是获取图片 url 后，插入到编辑器的方法
          const formdata = new FormData();
          formdata.append("file", resultFiles[0]);
          // 上传图片，返回结果，将图片插入到编辑器中
          const { data } = await $http.upload(formdata);
          insertImgFn(data.file_path);
        };
        editor.txt.html(data.details);
      });
    };

    const editSubmit = () => {
      state.dialogFormVisible = false;
      ruleForm.value.validate(async (validate) => {
        if (!validate) return;
        const res = await $http.updateShopsData({
          id: state.ruleForm.id ? state.ruleForm.id : "",
          shop_name: state.ruleForm.shop_name,
          email: state.ruleForm.email,
          phone: state.ruleForm.phone,
          address: state.ruleForm.address,
          banner: state.ruleForm.banner,
          start_business:
            new Date(state.time[0]).getHours() +
            ":" +
            new Date(state.time[0]).getMinutes() +
            ":" +
            new Date(state.time[0]).getSeconds(),
          end_business:
            new Date(state.time[1]).getHours() +
            ":" +
            new Date(state.time[1]).getMinutes() +
            ":" +
            new Date(state.time[1]).getSeconds(),
          details: editor.txt.html(),
          min_stock: state.ruleForm.min_stock,
        });
        if (res.status !== 200) return;
        emit("onSuccess", true);
        $message.success("修改成功");
      });
    };

    let loading = null;

    /*
    文件上传成功时的钩子
    */
    const handleAvatarSuccess = (res, file) => {
      state.ruleForm.banner = file.response.data.file_path;
      $message.success("上傳成功");
      loading.close();
    };
    /*
    上传文件之前的钩子，参数为上传的文件，
    若返回 false 或者返回 Promise 且被 
    reject，则停止上传
    */
    const beforeAvatarUpload = (file) => {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        $message.error("圖片大小不能超過 2M ！");
      }
      return isLt2M;
    };

    const onProgress = (event, file, fileList) => {
      loading = $loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    };

    const onError = (err) => {
      $message.error(err);
      loading.close();
    };

    return {
      state,
      init,
      ruleForm,
      editSubmit,
      handleAvatarSuccess,
      beforeAvatarUpload,
      onProgress,
      onError,
    };
  },
};
</script>
